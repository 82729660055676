import TagManager from 'react-gtm-module';

const GTM = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-MRF3JJM',
  };

  TagManager.initialize(tagManagerArgs);
};

export default GTM;
