export default {
  apiKey: 'AIzaSyCEWk5EFvGl1hyjewbnDIL0YimVSPFR2ms',
  authDomain: 'easydev-react.firebaseapp.com',
  databaseURL: 'https://easydev-react.firebaseio.com',
  projectId: 'easydev-react',
  storageBucket: '',
  messagingSenderId: '303737772185',
  appId: '1:303737772185:web:229ec02fff997b24154008',
  microsoftTenantId: 'fc44f29f-c851-4984-b1c7-3bfd8a6e3b4b',
};
