export default {
  client_id: '303737772185-3smu4b8uqr84mrje5o7hpuk5jqstsceb.apps.googleusercontent.com',
  project_id: 'easydev-react',
  auth_uri: 'https://accounts.google.com/o/oauth2/auth',
  token_uri: 'https://oauth2.googleapis.com/token',
  auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
  client_secret: 'Eup7MDP--R4FJ-4m1u2SfvkU',
  redirect_uris: [
    'https://easydev-react.firebaseapp.com/__/auth/handler',
    'https://easydev-react.qa.aspirity.com/google',
    'http://previews.aspirity.com/easydev/google',
    'http://localhost:3000/google',
  ],
  javascript_origins: [
    'http://localhost',
    'http://localhost:5000',
    'https://easydev-react.firebaseapp.com',
    'http://previews.aspirity.com',
    'https://easydev-react.qa.aspirity.com',
    'http://localhost:3000',
  ],
};
