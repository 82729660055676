export default [
  {
    id: 0,
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. By ignorant '
    + 'at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported'
    + ' by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    id: 1,
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. Moreover '
    + 'is possible he admitted sociable concerns… ',
  },
  {
    id: 2,
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her '
    + 'case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 3,
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. By ignorant '
    + 'at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported by extremity '
    + 'of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    id: 4,
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 5,
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. Enough at '
    + 'tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact '
    + 'demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 6,
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. '
    + 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported '
    + 'by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    id: 7,
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 8,
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. Enough '
    + 'at tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact '
    + 'demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 9,
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. '
    + 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported '
    + 'by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    id: 10,
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 11,
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. Enough at '
    + 'tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit '
    + 'compact demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 12,
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. '
    + 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported '
    + 'by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has '
    + 'sigh. Moreover is possible he admitted sociable concerns…',
  },
  {
    id: 13,
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
      + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 14,
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. '
    + 'Enough at tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact '
    + 'demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 15,
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. '
    + 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported '
    + 'by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    id: 16,
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    id: 17,
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. '
    + 'Enough at tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact '
    + 'demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
];
