import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { right } from '@/utils/directions';

const BuyNowButton = () => (
  <BuyLinkWrap>
    <BuyLink
      target="_blank"
      rel="noopener noreferrer"
      href="https://1.envato.market/Buy-now-React"
    >
      Buy now
    </BuyLink>
  </BuyLinkWrap>
);

export default BuyNowButton;

// region STYLES

const BuyLinkWrap = styled.div`
  display: none;
  position: fixed;
  bottom: 30px;
  z-index: 102;
  ${right}: 10px;

  @media screen and (min-width: 576px) {
    display: inherit;
    bottom: 50px;
    ${right}: 25px;
  }

  @media screen and (min-width: 768px) {
    bottom: 80px;
    ${right}: 55px;
  }
`;

const BuyLink = styled.a`
  box-shadow: 0 20px 30px 0 rgba(89, 181, 210, 0.31);
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  border-radius: 23px;
  height: 46px;
  padding: 10px 30px;
  transition: 0.3s;
  white-space: nowrap;
  border: none;
  background: linear-gradient(228deg, rgba(127, 244, 222, 0.95), rgb(62, 59, 224));
  color: white;

  &:hover {
    background: linear-gradient(228deg,
    ${darken(0.05, 'rgba(127, 244, 222, 0.95)')}, 
    ${darken(0.05, 'rgb(62, 59, 224)')});
    color: white;
    box-shadow: 0 5px 15px 0 rgba(89, 181, 210, 0.5);
  }
`;

// endregion
